import React from 'react';
import styles from './Cards.module.scss'
import ContentWrapper from "../../App/ContentWrapper/ContentWrapper";

const Cards = ({children, wrapperClass, contentClass}) => {
    return (
        <ContentWrapper
            contentClass={[styles.row, contentClass].join(" ")}
            wrapperClass={wrapperClass}
        >
            {children}
        </ContentWrapper>
    );
};

export default Cards;
