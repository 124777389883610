import React from 'react';
import styles from './BannerSwitch.module.scss';

const BannerSwitch = () => {
    return (
        <span className={styles.decor}></span>
    );
};

export default BannerSwitch;
