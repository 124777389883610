import React, {useRef, useState} from 'react';
import styles from './Header.module.scss'
import ContentWrapper from "../ContentWrapper/ContentWrapper";
import Logo from "../Logo/Logo";
import Navbar from "../Navbar/Navbar";
import ProfileLink from "../../UI/Profile/ProfileLink/ProfileLink";
import {useAuth} from "../../../context/AuthContext";
import {useClickOutside} from "../../../hooks/useClickOutside";
import classNames from "classnames";

const Header = () => {

    const {token} = useAuth()

    const [isShow, setIsShow] = useState(false)
    const ref = useRef()

    const toggleMenu = () => {
        setIsShow(prev => !prev)
    }

    useClickOutside(ref, () => setIsShow(false))
    // TODO: исправить overflow при раскрытии меню
    return (
        <div ref={ref}>
            <ContentWrapper contentClass={classNames(styles.row, {[`${styles.show}`]: isShow})} wrapperClass={styles.wrapper}>
                <Logo/>
                <Navbar toggle={toggleMenu} isShow={isShow} />
                {token && <ProfileLink/>}
            </ContentWrapper>
        </div>
    );
};

export default Header;
