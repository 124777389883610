import React, {useEffect, useState} from 'react';
import styles from './PersonalCard.module.scss';
import CardHeader from "../../../../components/UI/Cards/Card/CardHeader/CardHeader";
import Card from "../../../../components/UI/Cards/Card/Card";
import classNames from "classnames";
import {ReactComponent as HideIcon} from "../../../../assets/icons/hide.svg"

const PersonalCard = ({person}) => {

    const [isHide, setIsHide] = useState(true)

    const showHidePassword = () => {
        setIsHide(prev => !prev)
    }

    return (
        <Card height={'fit'}>
            <CardHeader title='Личная информация'/>
            <div className={classNames(styles.content, 'body-18px')}>
                <div className={styles.row}>
                    <p className={styles.rowLeft}>Имя</p>
                    <p className={styles.rowRight}>{person.name}</p>
                </div>
                <div className={styles.row}>
                    <p className={styles.rowLeft}>Фамилия</p>
                    <p className={styles.rowRight}>{person.secondName}</p>
                </div>
                <div className={styles.row}>
                    <p className={styles.rowLeft}>E-mail</p>
                    <p className={styles.rowRight}>{person.email}</p>
                </div>
                <div className={styles.row}>
                    <p className={styles.rowLeft}>Телефон</p>
                    <p className={styles.rowRight}>{person.phone}</p>
                </div>
                <div className={styles.row}>
                    <p className={styles.rowLeft}>Дата рождения</p>
                    <p className={styles.rowRight}>{person.birthday}</p>
                </div>
                <div className={styles.row}>
                    <p className={styles.rowLeft}>Пол</p>
                    <p className={styles.rowRight}>{person.gender}</p>
                </div>
            </div>
            <div className={styles.password}>
                <div className={classNames(styles.row, 'body-16px')}>
                    <p className={styles.rowLeft}>
                        Пароль
                    </p>
                    <p className={classNames(styles.right, 'body-16px')}>
                        <span>
                            {isHide ? '****************' : person.password}
                            <HideIcon onClick={showHidePassword}/>
                        </span>
                    </p>
                </div>
                <a href="#" className={classNames(styles.link, 'body-16px')}>Сменить пароль</a>
            </div>
        </Card>
    );
}

export default PersonalCard
