import React from 'react';
import styles from './CardHeader.module.scss';
import BlueLink from "../../../common/Links/BlueLink/BlueLink";

const CardHeader = ({title, icon, linkText, to}) => {
    return (
        <div className={styles.header}>
            <h3 className={`${styles.title} header-32px`}>
                {title}
                {icon && <img src={icon} className={styles.icon} alt=""/>}
            </h3>
            {to && linkText && <BlueLink to={to}>{linkText}</BlueLink>}
        </div>
    );
};

export default CardHeader;
