import {createContext, useContext, useEffect, useState} from "react";

export const themes = {
    dark: 'dark',
    light: 'light'
}

const getTheme = () => {
    const theme = `${window?.localStorage?.getItem('theme')}`

    if (Object.values(themes).includes(theme)) return theme

    const userMedia = window.matchMedia('(prefers-color-scheme: light)')
    if (userMedia.matches) return themes.light

    return themes.dark
}

const ThemeContext = createContext(null)

export const ThemeProvider = ({children}) => {

    const [theme, setTheme] = useState(getTheme())

    useEffect(() => {
        document.documentElement.dataset.theme = theme
        localStorage.setItem('theme', theme)
    }, [theme])

    return <ThemeContext.Provider value={{theme, setTheme}}>{children}</ThemeContext.Provider>
}

export const useTheme = () => {
    const context = useContext(ThemeContext)
    if (!context)
        throw new Error('Хук useTheme должен быть использован внутри ThemeProvider')

    return context
}
