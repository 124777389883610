import React from 'react';
import styles from './WelcomeCard.module.scss';
import CardHeader from "../../../../components/UI/Cards/Card/CardHeader/CardHeader";
import Card from "../../../../components/UI/Cards/Card/Card";
import classNames from "classnames";
import {ReactComponent as VkIcon} from "../../../../assets/icons/vk.svg"
import {ReactComponent as TgIcon} from "../../../../assets/icons/tg.svg"

const WelcomeCard = ({name}) => {
    return (
        <Card height={'fit'}>
            <CardHeader title={`Добрый день, ${name}!`}/>
            <div className={styles.content}>
                <div className={styles.card}>
                    <div className={styles.cardHeader}>
                        <div className={styles.cardTitle}>Персональный менеджер</div>
                    </div>
                    <div className={styles.cardContent}>
                        <p className={'body-18px'}>Лебедев Алексей Андреевич</p>
                    </div>
                </div>
                <div className={styles.card}>
                    <div className={styles.cardHeader}>
                        <div className={styles.cardTitle}>Контакты</div>
                        <div className={styles.cardLinks}>
                            <a href="#">
                                <VkIcon />
                            </a>
                            <a href="#">
                                <TgIcon />
                            </a>
                        </div>
                    </div>
                    <div className={styles.cardContent}>
                        <a className={'body-16px'} href="tel:8 843 233-43-30">8 843 233-43-30</a>
                        <a className={'body-16px'} href="mailto:ceo@artlebedev.ru">ceo@artlebedev.ru</a>
                    </div>
                </div>
            </div>
        </Card>
    );
}

export default WelcomeCard
