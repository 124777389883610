import React from 'react';
import styles from './ProfilePage.module.scss';
import Banner from "../../components/UI/Banner/Banner";
import Cards from "../../components/UI/Cards/Cards";
import Stack from "../../components/UI/common/Stack/Stack";
import WelcomeCard from "./Cards/WelcomeCard/WelcomeCard";
import PersonalCard from "./Cards/PersonalCard/PersonalCard";
import LegalCard from "./Cards/LegalCard/LegalCard";
import RewardsCard from "./Cards/RewardsCard/RewardsCard";
import PurchasesCard from "./Cards/PurchasesCard/PurchasesCard";
import DocumentsCard from "./Cards/DocumentsCard/DocumentsCard";
import {ReactComponent as RewardIcon1} from "../../assets/icons/rewards/open-ip.svg";
import {ReactComponent as RewardIcon2} from "../../assets/icons/rewards/search-location.svg";
import {ReactComponent as RewardIcon3} from "../../assets/icons/rewards/beznal.svg";
import {ReactComponent as RewardIcon4} from "../../assets/icons/rewards/online-kassa.svg";
import {ReactComponent as RewardIcon5} from "../../assets/icons/rewards/naim.svg";
import {ReactComponent as RewardIcon6} from "../../assets/icons/rewards/open-ip-2.svg";

const ProfilePage = () => {

    const personalInfo = {
        name: 'Руслан',
        secondName: 'Андреев',
        email: 'ceo@artlebedev.ru',
        phone: '8 843 233-43-30',
        birthday: '27 декабря 1994 г.',
        gender: 'Мужской',
        password: 'strongpassword123'
    }

    const rewards = [
        {
            id: 0,
            name: 'За открытие ИП',
            Icon: <RewardIcon1/>
        },
        {
            id: 1,
            name: 'За поиск локации',
            Icon: <RewardIcon2/>
        },
        {
            id: 2,
            name: 'За безналичный расчет',
            Icon: <RewardIcon3/>
        },
        {
            id: 3,
            name: 'За онлайн кассу',
            Icon: <RewardIcon4/>
        },
        {
            id: 4,
            name: 'За найм сотрудника',
            Icon: <RewardIcon5/>
        },
        {
            id: 5,
            name: 'За открытие ИП',
            Icon: <RewardIcon6/>
        },
    ]

    return (
        <div className={'page'}>
            <Banner/>
            <Cards wrapperClass={styles.cards}>
                <Stack className={styles.stack}>
                    <WelcomeCard name={personalInfo.name}/>
                    <PersonalCard person={personalInfo} />
                    <LegalCard />
                </Stack>
                <Stack className={styles.stack}>
                    <RewardsCard rewards={rewards} />
                    <PurchasesCard />
                    <DocumentsCard />
                </Stack>
            </Cards>
        </div>
    );
}

export default ProfilePage
