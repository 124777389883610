import React, {useState} from 'react';
import styles from './Edu.module.scss'
import Button from "../../common/Button/Button";
import Card from "../Card/Card";
import {Link} from "react-router-dom";
import BlueLink from "../../common/Links/BlueLink/BlueLink";
import ProgressBar from "../../common/ProgressBar/ProgressBar";

const Edu = ({size}) => {

    const [progress, setProgress] = useState(10);

    return (
        <Card size={size}>
            <div className={styles.header}>
                <h6 className='body-14px uppercase'>МОДУЛЬ 1: ОТКРЫТЬ ИП ИЛИ ООО</h6>
                <BlueLink to={'edu'}>Все уроки</BlueLink>
            </div>
            <div className={styles.wrapper}>
                <h3>Создаем карту клиента</h3>
                <p className='body-18px'>Урок 3/20</p>
                <div className={styles.progressWrapper}>
                    <div className={styles.progressWrapperContent}>
                        <h5 className='header-24px'>Прогресс обучения</h5>
                        <p className='body-14px'>Модулей пройдено: 0/7</p>
                    </div>
                    <div className={styles.progress}>
                        <ProgressBar
                            progress={progress}
                        />
                    </div>
                </div>
                <Link to={'edu'}>
                    <Button size={'full'} color={'green'}>Продолжить обучение</Button>
                </Link>
            </div>
        </Card>
    );
};

export default Edu;
