import React from 'react';
import styles from './Stack.module.scss';
import classNames from "classnames";

const Stack = ({children, className}) => {
    return (
        <div className={classNames(styles.Stack, className)}>
            {children}
        </div>
    );
}

export default Stack
