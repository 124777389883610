import React from 'react';
import styles from './LogoutLink.module.scss';
import {useAuth} from "../../../../../context/AuthContext";

const LogoutLink = () => {

    const {logOut} = useAuth()

    return (
        <div onClick={logOut} className={styles.block}>
            <div className={styles.icon}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9" stroke="#8995A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M13 17L18 12L13 7" stroke="#8995A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M18 12L9 12" stroke="#8995A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            <div className={styles.text}>
                Выйти
            </div>
        </div>
    );
};

export default LogoutLink;
