import React, {useState} from 'react';
import Input from "../../components/UI/common/Input/Input";
import classNames from "classnames";
import styles from "./NewsPage.module.scss";
import ContentWrapper from "../../components/App/ContentWrapper/ContentWrapper";
import PageRow from "../../components/UI/PageRow/PageRow";
import PageContent from "../../components/UI/PageContent/PageContent";
import TagsBar from "../../components/UI/TagsBar/TagsBar";
import {useDashboard} from "../../context/DashboardContext";
import NewsItem from "../../components/UI/Cards/News/NewsItem/NewsItem";

const NewsPage = () => {

    const [tags, setTags] = useState([
        {
            id: 0,
            name: 'Все'
        },
        {
            id: 1,
            name: 'Найм персонала'
        },
        {
            id: 2,
            name: 'Упаковка'
        },
        {
            id: 3,
            name: 'Обслуживание'
        },
    ]);

    const {news} = useDashboard()

    const [activeTag, setActiveTag] = useState({
        id: 0,
        name: 'Все'
    })

    const changeTag = (tag) => {
        setActiveTag({...tag})
    }

    const [search, setSearch] = useState('');

    return (
        <div className={'page'}>
            <ContentWrapper>
                <PageRow>
                    <PageContent
                        title={'Новости'}
                        showMore={true}
                        headerChildren={
                            <Input
                                className={classNames(styles.input, { [styles.inSearch]: search} )}
                                value={search}
                                onChange={event => setSearch(event.target.value)}
                                label={'Поиск'}
                            />
                        }
                    >
                        <TagsBar className={styles.tags} tags={tags} activeTag={activeTag} onClick={changeTag}/>
                        <div className={styles.cards}>
                            {news?.map(post => <NewsItem className={styles.card} item={post} key={post.id}/>)}
                        </div>
                    </PageContent>
                </PageRow>
            </ContentWrapper>
        </div>
    );
};

export default NewsPage;
