import {createContext, useContext, useEffect, useState} from "react";
import service from "../services/DashboardService";

export const DashboardContext = createContext(null)

export const DashboardProvider = ({children}) => {

    const [isLoading, setIsLoading] = useState(false)
    const [news, setNews] = useState()
    const [eduBase, setEduBase] = useState()

    useEffect(() => {
        fetchDashboard()
    }, [])

    async function fetchDashboard() {
        try {
            setIsLoading(true)
            const {news, edubase} = await service.fetchDashboard()

            setNews(news)
            setEduBase(edubase)
        } catch (e) {
            console.log(e)
        } finally {
            setIsLoading(false)
        }
    }

    const contextValue = {
        news,
        eduBase,
        isLoading
    }

    return <DashboardContext.Provider value={contextValue}>{children}</DashboardContext.Provider>
}

export const useDashboard = () => {
    const context = useContext(DashboardContext)
    if (!context)
        throw new Error('Хук useDashboard должен быть использован внутри DashboardProvider')

    return context
}
