import React from 'react';
import styles from './News.module.scss';
import Card from "../Card/Card";
import icon from "../../../../assets/Cards/News/hand.png"
import CardHeader from "../Card/CardHeader/CardHeader";
import NewsItem from "./NewsItem/NewsItem";

const News = ({size, posts}) => {

    return (
        <Card size={size} className={styles.card}>
            <CardHeader
                to={'news'}
                title={'Новости'}
                linkText={'Все новости'}
                icon={icon}
            />
            <div className={styles.list}>
                {
                    posts && posts.map(post => <NewsItem item={post} key={post.id}/>)
                }
            </div>
        </Card>
    );
};

export default News;
