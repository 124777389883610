import React from 'react';
import styles from './PageContent.module.scss';
import classNames from "classnames";
import {useNavigate} from "react-router-dom";

const PageContent = ({children, title, headerChildren, showMore = false, onClickShowMore}) => {

    const navigate = useNavigate()

    return (
        <section className={styles.block}>
            <button onClick={() => navigate(-1)} className={classNames(styles.back, 'body-16px')}>
                Назад
            </button>
            <div className={classNames(styles.header, {[`${styles.margin}`]: title})}>
                {title &&
                    <h1 className={classNames(styles.title, 'header-48px')}>
                        {title}
                    </h1>
                }
                {headerChildren}
            </div>
            <div className={styles.content}>
                {children}
            </div>
            {showMore &&
                <div className={classNames(styles.showMore, 'body-16px')}>
                    <span onClick={onClickShowMore}>Показать еще</span>
                </div>
            }
        </section>
    );
};

export default PageContent;
