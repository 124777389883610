import React from 'react';
import Banner from "../../components/UI/Banner/Banner";
import styles from './DashboardPage.module.scss'
import Cards from "../../components/UI/Cards/Cards";
import Promotion from "../../components/UI/Cards/Promotion/Promotion";
import Edu from "../../components/UI/Cards/Edu/Edu";
import EduBase from "../../components/UI/Cards/EduBase/EduBase";
import ServicesShop from "../../components/UI/Cards/ServicesShop/ServicesShop";
import News from "../../components/UI/Cards/News/News";
import Social from "../../components/UI/Cards/Social/Social";
import {useDashboard} from "../../context/DashboardContext";

const DashboardPage = () => {

    const {news, eduBase, isLoading} = useDashboard()

    return (
        <div className={'page'}>
            <Banner/>
            <Cards wrapperClass={styles.cards}>
                <Promotion/>
                <Edu/>
                <EduBase size='full' posts={eduBase}/>
                <ServicesShop/>
                <div>
                    <News posts={news}/>
                    <Social/>
                </div>
            </Cards>
        </div>
    );
};

export default DashboardPage;
