import React from 'react';
import styles from './ProgressBar.module.scss';

const ProgressBar = ({progress}) => {
    return (
        <div className={styles.wrapper}>
            <h6 className='body-14px'>Выполнено: {progress}%</h6>
            <div className={styles.progress}>
                <i style={{width: progress + '%'}}></i>
            </div>
        </div>
    );
};

export default ProgressBar;
