import React, {useState} from 'react';
import ContentWrapper from "../../components/App/ContentWrapper/ContentWrapper";
import PageContent from "../../components/UI/PageContent/PageContent";
import Input from "../../components/UI/common/Input/Input";
import {Link} from "react-router-dom";
import PageRow from "../../components/UI/PageRow/PageRow";
import styles from "./EduPage.module.scss"
import EduPageCard from "./EduPageCard/EduPageCard";
import icon1 from "../../assets/pages/EduPage/cards/1.png"
import icon2 from "../../assets/pages/EduPage/cards/2.png"
import icon3 from "../../assets/pages/EduPage/cards/3.png"
import icon4 from "../../assets/pages/EduPage/cards/4.png"
import icon5 from "../../assets/pages/EduPage/cards/5.png"
import icon6 from "../../assets/pages/EduPage/cards/6.png"

const EduPage = () => {

    const [files, setFiles] = useState([
        {
            id: 0,
            name: 'Превью',
            type: 'image',
            format: 'jpeg',
            link: '#'
        },
        {
            id: 1,
            name: 'План на квартал',
            type: 'document',
            format: 'pdf',
            link: '#'
        },
        {
            id: 2,
            name: 'Видео инструкция',
            type: 'video',
            format: 'mp4',
            link: '#'
        },
        {
            id: 3,
            name: 'Аудио подкаст',
            type: 'audio',
            format: 'wave',
            link: '#'
        },
    ]);

    const [links, setLinks] = useState([
        { id: 0, title: 'Поиска аккредитованных удостоверяющих центров', link: '#'},
        { id: 1, title: 'ФНС с информацией по ЭЦП и ответами на вопросы', link: '#'},
        { id: 2, title: 'ФНС с информацией по ЭЦП и ответами на вопросы', link: '#'}
    ])

    const [modules, setModules] = useState([
        { id: 0, title: 'Открыть ИП', completed: 2, articles: [
                {
                    id: 0,
                    title: 'Информация по открытию ИП',
                    link: '#',
                    status: 'Пройден'
                },
                {
                    id: 1,
                    title: 'Коды ОКВЭД для тех, у кого уже открыто ИП',
                    link: '#',
                    status: 'На проверке'
                },
                {
                    id: 2,
                    title: 'Обязательные платежи в течение года',
                    link: '#',
                    status: 'Пройден'
                },
                {
                    id: 3,
                    title: 'Обязательные платежи в течение года',
                    link: '#',
                },
                {
                    id: 4,
                    title: 'Финальный тест',
                    link: '#',
                },
            ], link: '/edubase/module', icon: icon1,
            active: true
        },
        { id: 1, title: 'Поиск локации', articles: [], link: '/edubase/module', icon: icon2,},
        { id: 2, title: 'Безналичный расчет', articles: [], link: '/edubase/module', icon: icon3,},
        { id: 3, title: 'Онлайн касса', articles: [], link: '/edubase/module', icon: icon4,},
        { id: 4, title: 'Найм сотрудников', articles: [], link: '/edubase/module', icon: icon5,},
        { id: 5, title: 'Открытие первой точки', articles: [], link: '/edubase/module', icon: icon6, closed: true},
    ])

    return (
        <div className={'page'}>
            <ContentWrapper>
                <PageRow>
                    <PageContent
                        title={'Обучение HOHORO'}
                        headerChildren={<Input label={'Поиск'} />}
                    >
                        <div className={styles.cards}>
                            {modules.map((module, index) => <EduPageCard index={index} key={module.id} module={module} modules={modules} />)}
                        </div>
                    </PageContent>
                </PageRow>
            </ContentWrapper>
        </div>
    );
};

export default EduPage;
