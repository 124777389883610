import React from 'react';
import styles from './NewsItem.module.scss';
import moment from "moment/moment";
import classNames from "classnames";

const NewsItem = ({item, className}) => {
    return (
        <div className={classNames(styles.element, className)}>
            <a href='#' className={styles.image}>
                <img src={item.image} alt=""/>
            </a>
            <div className={styles.content}>
                <div className={styles.date}>
                    {moment(item.date).format('DD.MM.YYYY')}
                </div>
                <h5 className={`${styles.title} body-m`}>
                    <a href="#">{item.excerpt}</a>
                </h5>
            </div>
        </div>
    );
};

export default NewsItem;
