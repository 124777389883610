import React from 'react';
import styles from './LegalCard.module.scss';
import CardHeader from "../../../../components/UI/Cards/Card/CardHeader/CardHeader";
import Card from "../../../../components/UI/Cards/Card/Card";
import classNames from "classnames";

const LegalCard = () => {
    return (
        <Card height={'fit'}>
            <CardHeader title='Юридическая информация' />
            <div className={classNames(styles.content, 'body-16px')}>
                <div className={styles.row}>
                    <p className={styles.rowLeft}>Наименование</p>
                    <p className={styles.rowRight}>ООО «ХОХОРО»</p>
                </div>
                <div className={styles.row}>
                    <p className={styles.rowLeft}>ИНН</p>
                    <p className={styles.rowRight}>1658234070</p>
                </div>
                <div className={styles.row}>
                    <p className={styles.rowLeft}>КПП</p>
                    <p className={styles.rowRight}>165801001</p>
                </div>
                <div className={styles.row}>
                    <p className={styles.rowLeft}>ОГРН / ОГРНИП</p>
                    <p className={styles.rowRight}>1211600062376</p>
                </div>
                <div className={styles.row}>
                    <p className={styles.rowLeft}>Юридический адрес</p>
                    <p className={styles.rowRight}>420034, РТ, г. Казань, ул. Декабристов, зд. 85Б, пом. 1100</p>
                </div>
                <div className={styles.row}>
                    <p className={styles.rowLeft}>Почтовый адрес</p>
                    <p className={styles.rowRight}>420066, РТ, г. Казань, ул. Черноморская 3, а/я 226</p>
                </div>
                <div className={styles.row}>
                    <p className={styles.rowLeft}>Банк</p>
                    <p className={styles.rowRight}>ТОЧКА ПАО БАНКА "ФК ОТКРЫТИЕ"</p>
                </div>
                <div className={styles.row}>
                    <p className={styles.rowLeft}>БИК банка</p>
                    <p className={styles.rowRight}>044525999</p>
                </div>
                <div className={styles.row}>
                    <p className={styles.rowLeft}>Р/C</p>
                    <p className={styles.rowRight}>40702810907500010883</p>
                </div>
                <div className={styles.row}>
                    <p className={styles.rowLeft}>Корр/с</p>
                    <p className={styles.rowRight}>30101810845250000999</p>
                </div>
            </div>
            <div className={styles.tip}>
                Если у вас изменились данные, сообщите <a className={styles.link} href={'#'}>менеджеру</a>
            </div>
        </Card>
    );
}

export default LegalCard
