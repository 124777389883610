import {$api} from "./api";
import Cookies from "js-cookie"

export default class AuthService {

    static async login(email, password, remember = false) {
        const loginData = {
            email,
            password
        }

        const {data} = await $api.post('/login', loginData)

        Cookies.set('token', data.token, {expires: remember ? 365 : null})

        return data.token
    }

    static async fetchUser() {
        const {data} = await $api.get('/user')

        return data
    }

    static async logOut() {
        await $api.post('/logout')
        Cookies.remove('token')
    }
}
