import React, {useState} from 'react';
import styles from './EduBasePage.module.scss';
import ContentWrapper from "../../components/App/ContentWrapper/ContentWrapper";
import PageRow from "../../components/UI/PageRow/PageRow";
import PageContent from "../../components/UI/PageContent/PageContent";
import Input from "../../components/UI/common/Input/Input";
import EduBaseItem from "../../components/UI/Cards/EduBase/EduBaseItem/EduBaseItem";
import {useDashboard} from "../../context/DashboardContext";
import TagsBar from "../../components/UI/TagsBar/TagsBar";
import classNames from "classnames";

const EduBasePage = () => {

    const {eduBase} = useDashboard()

    const [tags, setTags] = useState([
        {
            id: 0,
            name: 'Все'
        },
        {
            id: 1,
            name: 'Видео'
        },
        {
            id: 2,
            name: 'Лайфхаки'
        },
        {
            id: 3,
            name: 'Открыть ИП'
        },
        {
            id: 4,
            name: 'Поиск локации'
        },
        {
            id: 5,
            name: 'Безналичный расчет'
        },
        {
            id: 6,
            name: 'Онлайн касса'
        },
    ]);

    const [activeTag, setActiveTag] = useState({
        id: 0,
        name: 'Все'
    })

    const [search, setSearch] = useState('');

    const changeTag = (tag) => {
        setActiveTag({...tag})
    }

    return (
        <div className={'page'}>
            <ContentWrapper>
                <PageRow>
                    <PageContent
                        showMore={true}
                        onClickShowMore={() => {}}
                        title={search ? 'Поиск по базе знаний' : 'База знаний'}
                        headerChildren={
                            <Input
                                className={classNames(styles.input, {[styles.inSearch]: search})}
                                value={search}
                                onChange={event => setSearch(event.target.value)}
                                label={'Поиск'}
                            />
                        }
                    >
                        {search
                            ?
                            <>
                                <div className={styles.found}>
                                    <div className={styles.foundItem}>
                                        Запустили сайт Торгового дома HOHORO с Филиппом
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <TagsBar className={styles.tags} tags={tags} activeTag={activeTag} onClick={changeTag}/>
                                <div className={styles.cards}>
                                    {eduBase?.map(post =>
                                        <div key={post.id} className={styles.card}>
                                            <EduBaseItem width={'auto'} item={post}/>
                                        </div>
                                    )}
                                </div>
                            </>
                        }
                    </PageContent>
                </PageRow>
            </ContentWrapper>
        </div>
    );
};

export default EduBasePage;
