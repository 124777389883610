import React from 'react';
import styles from './Switch.module.scss';

const Switch = ({label, value, id, onChange, ...props}) => {
    return (
        <label htmlFor={id} className={styles.wrapper}>
            <input id={id} onChange={onChange} checked={value} className={styles.input} type={'checkbox'} />
            <div className={styles.ellipse}>
                <span className={styles.thumb}></span>
            </div>
            {label &&
                <div className={`${styles.label} body-s`}>
                    {label}
                </div>
            }
        </label>
    );
};

export default Switch;
