import React from 'react';
import styles from './Card.module.scss'
import classNames from "classnames";

const Card = ({children, size = 'half', className, height, ...props}) => {

    return (
        <div className={classNames(styles.card, { [`${styles[height]}`]: height}, [className, styles[size]])} {...props}>
            {children}
        </div>
    );
};

export default Card;
