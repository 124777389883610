import React from 'react';
import styles from './SidebarCard.module.scss';
import classNames from "classnames";

const SidebarCard = ({children, title, color = 'white'}) => {
    return (
        <div className={classNames(styles.block, `${styles[color]}`)}>
            {title && <h5 className={classNames(styles.title, 'header-24px')}>{title}</h5>}
            {children}
        </div>
    );
};

export default SidebarCard;
