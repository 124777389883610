import axios from "axios";
import Cookies from "js-cookie";

const API_URL = process.env.REACT_APP_API_URL

export const $api = axios.create({
    baseURL: API_URL,
    withCredentials: true
})

$api.interceptors.request.use(async (request) => {
    request.headers.Authorization = `Bearer ${Cookies.get('token')}`

    return request
})

$api.interceptors.response.use(response => response, error => {
    const {status} = error.response

    if (status === 401) {
        Cookies.remove('token')
        document.location.href = '/login'
    }

    return Promise.reject(error)
})
