import React, {useState} from 'react';
import styles from './ServicesPage.module.scss';
import PageContent from "../../components/UI/PageContent/PageContent";
import PageRow from "../../components/UI/PageRow/PageRow";
import ContentWrapper from "../../components/App/ContentWrapper/ContentWrapper";
import TagsBar from "../../components/UI/TagsBar/TagsBar";
import image1 from "../../assets/Cards/ServicesShop/image1.png";
import image2 from "../../assets/Cards/ServicesShop/image2.png";
import ServiceCard from "../../components/UI/Cards/ServicesShop/ServiceCard/ServiceCard";

const ServicesPage = () => {

    const [tags, setTags] = useState([
        {
            id: 0,
            name: 'Все'
        },
        {
            id: 1,
            name: 'Найм персонала'
        },
        {
            id: 2,
            name: 'Упаковка'
        },
        {
            id: 3,
            name: 'Обслуживание'
        }
    ]);

    const [activeTag, setActiveTag] = useState({
        id: 0,
        name: 'Все'
    })

    const changeTag = (tag) => {
        setActiveTag({...tag})
    }

    const [cards, setCards] = useState([
        {
            id: 1,
            image: image1,
            title: "Помощь с открытием ИП/ООО"
        },
        {
            id: 2,
            image: image2,
            title: "Аутсорсинг обслуживания точек"
        },
        {
            id: 3,
            image: image1,
            title: "Помощь с открытием ИП/ООО"
        },
        {
            id: 4,
            image: image2,
            title: "Аутсорсинг обслуживания точек"
        },
        {
            id: 5,
            image: image1,
            title: "Помощь с открытием ИП/ООО"
        },
        {
            id: 6,
            image: image2,
            title: "Аутсорсинг обслуживания точек"
        },
    ])

    return (
        <div className={'page'}>
            <ContentWrapper>
                <PageRow>
                    <PageContent
                        title={'Магазин услуг'}
                    >
                        <TagsBar className={styles.tags} tags={tags} activeTag={activeTag} onClick={changeTag}/>
                        <div className={styles.cards}>
                            {cards.map(card => <ServiceCard key={card.id} size={'full'} card={card} className={styles.card}/>)}
                        </div>
                    </PageContent>
                </PageRow>
            </ContentWrapper>
        </div>
    );
};

export default ServicesPage;
