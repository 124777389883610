import React from 'react';

const Wrapper = ({ children, wrapperClass }) => {
    const className = wrapperClass ? wrapperClass + ' wrapper' : 'wrapper';

    return (
        <section className={className}>
            { children }
        </section>
    );
};

export default Wrapper;
