import {$api} from "./api";

export default class DashboardService {

    static async fetchDashboard() {
        const {data} = await $api.get('/dashboard')

        return data
    }
}
