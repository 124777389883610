import React from 'react';
import styles from './EduBaseItem.module.scss';
import classNames from "classnames";

const EduBaseItem = ({item, width}) => {
    return (
        <a href='#' className={classNames(styles.element, { [`${styles[width]}`]: width })}>
            <div className={styles.header}>
                <div className={styles.image}>
                    <img src={item.image} alt=""/>
                </div>
                <div className={`body-s ${styles.right}`}>
                    <p>12.12.2022</p>
                    <p>#видео #лайфхаки</p>
                </div>
            </div>
            <div className={styles.content}>
                <h5 className={`${styles.title} header-24px`}>
                    {item.title}
                </h5>
                <p className={styles.body}>
                    {item.excerpt}
                </p>
            </div>
        </a>
    );
};

export default EduBaseItem;
