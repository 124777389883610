import React, {useState} from 'react';
import styles from "./LoginPage.module.scss"
import Button from "../../components/UI/common/Button/Button";
import {useAuth} from "../../context/AuthContext";
import {Navigate} from "react-router-dom";
import Switch from "../../components/UI/Switch/Switch";
import ContentWrapper from "../../components/App/ContentWrapper/ContentWrapper";
import bg from "../../assets/pages/LoginPage/login-bg.png"
import Logo from "../../components/App/Logo/Logo";
import Input from "../../components/UI/common/Input/Input";

const LoginPage = () => {

    const [data, setData] = useState({
        email: '',
        password: '',
        remember: true
    })

    const {login, token} = useAuth()

    const submit = (e) => {
        e.preventDefault()
        login(data)
    }

    if (token) return <Navigate to={'/'}/>

    return (
        <div className="page">
            <ContentWrapper>
                <div className={styles.section}>
                    <form onSubmit={submit} className={styles.form}>
                        <div className={styles.logo}>
                            <Logo onlyLogo isLink={false}/>
                        </div>
                        <div>
                            <h2 className="header-40px">Вход в личный кабинет</h2>
                            <div className={styles.input}>
                                <Input
                                    value={data.email}
                                    type={'text'}
                                    id={'email'}
                                    onChange={(e) => setData(data => ({...data, email: e.target.value}))}
                                    label={'E-mail'}
                                />
                            </div>
                            <div className={styles.input}>
                                <Input
                                    value={data.password}
                                    type={'password'}
                                    id={'password'}
                                    onChange={(e) => setData(data => ({...data, password: e.target.value}))}
                                    label={'Пароль'}
                                />
                            </div>
                            <div className={styles.actions}>
                                <div className={styles.submit}>
                                    <Button type='submit' size={'full'}>Войти</Button>
                                </div>
                                <a className={styles.link} href='#'>Стать партнёром</a>
                            </div>
                        </div>
                        <Switch
                            label={'Запомнить устройство'}
                            value={data.remember}
                            onChange={(e) => setData(data => ({...data, remember: e.target.checked}))}
                        />
                    </form>
                    <div className={styles.image}>
                        <img src={bg} alt=""/>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    );
};

export default LoginPage;
