import React from 'react';
import styles from './Promotion.module.scss'
import Button from "../../common/Button/Button";
import Card from "../Card/Card";
import {Link} from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from "swiper";
import 'swiper/css';
import "swiper/css/pagination";
import "./Promotion-swiper.scss"

const Promotion = ({size}) => {
    return (
        <Card className={styles.sliderWrapper} size={size}>
            <Swiper
                modules={[Pagination, Autoplay]}
                spaceBetween={8}
                slidesPerView={1}
                loop={true}
                grabCursor={true}
                speed={800}
                autoplay={{
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true
                }}
                pagination={{
                    clickable: true,
                }}
                className='promotion-slider'
            >
                <SwiperSlide>
                    <div className={styles.element}>
                        <div className={styles.header + ' body-12px' + ' uppercase'}>
                            <h6>АКЦИЯ</h6>
                            <p>до 27.05.22</p>
                        </div>
                        <div className={styles.content + ' body-18px'}>
                            <h3>Кофе свежей обжарки</h3>
                            <p>Успей заказать для своей точки самообслуживания свежий кофе с обжаркой</p>
                            <Link to={'shop'}>
                                <Button color={"green"} size={"auto"}>
                                    Заказать
                                </Button>
                            </Link>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={styles.element}>
                        <div className={styles.header + ' body-12px' + ' uppercase'}>
                            <h6>АКЦИЯ</h6>
                            <p>до 27.05.22</p>
                        </div>
                        <div className={styles.content + ' body-18px'}>
                            <h3>Кофе свежей обжарки</h3>
                            <p>Успей заказать для своей точки самообслуживания свежий кофе с обжаркой</p>
                            <Link to={'shop'}>
                                <Button color={"green"} size={"auto"}>
                                    Заказать
                                </Button>
                            </Link>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={styles.element}>
                        <div className={styles.header + ' body-12px' + ' uppercase'}>
                            <h6>АКЦИЯ</h6>
                            <p>до 27.05.22</p>
                        </div>
                        <div className={styles.content + ' body-18px'}>
                            <h3>Кофе свежей обжарки</h3>
                            <p>Успей заказать для своей точки самообслуживания свежий кофе с обжаркой</p>
                            <Link to={'shop'}>
                                <Button color={"green"} size={"auto"}>
                                    Заказать
                                </Button>
                            </Link>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </Card>
    );
};

export default Promotion;
