import React from 'react';
import styles from './TagsBar.module.scss';
import classNames from "classnames";

const TagsBar = ({tags, activeTag, onClick, className}) => {

    const tagClick = (tag, event) => {
        onClick(tag, event)
    }

    return (
        <div className={styles.wrapper}>
            <div className={classNames(styles.row, className)}>
                {tags?.map(tag =>
                    <div
                        key={tag.id}
                        className={classNames(styles.tag, {[styles.active]: activeTag.id === tag.id} ,'header-24px')}
                        onClick={event => tagClick(tag, event)}
                    >
                        {tag.name}
                    </div>
                )}
            </div>
        </div>
    );
};

export default TagsBar;
