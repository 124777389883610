import Header from "./components/App/Header/Header";
import Footer from "./components/App/Footer/Footer";
import AppRouter from "./components/App/AppRouter";
import {ThemeProvider} from "./context/ThemeContext";

function App() {
  return (
    <ThemeProvider>
      <Header/>
      <AppRouter/>
      <Footer/>
    </ThemeProvider>
  );
}

export default App;
