import React, {useRef, useState} from 'react';
import styles from './ProfileLink.module.scss'
import userImage from '../../../../assets/user.png'
import Menu from "../../Menu/Menu";
import BlueLink from "../../common/Links/BlueLink/BlueLink";
import classNames from "classnames";
import {useClickOutside} from "../../../../hooks/useClickOutside";
import LogoutLink from "../../common/Links/LogoutLink/LogoutLink";

const ProfileLink = () => {

    const [menuVisible, setMenuVisible] = useState(false);

    const openMenu = () => {
        setMenuVisible(prev => !prev)
    }

    const ref = useRef()

    useClickOutside(ref, () => setMenuVisible(false))

    return (
        <div ref={ref} className={styles.block}>
            <div className={classNames(styles.row, {[`${styles.active}`]: menuVisible})} onClick={openMenu}>
                <div className={styles.image}>
                    <img src={userImage} alt=""/>
                </div>
                <div className={styles.name}>
                    Андреев Р. Г.
                </div>
            </div>
            <Menu isVisible={menuVisible}>
                <div className={styles.links}>
                    <BlueLink collapsible={false} to={'/profile'}>Мой профиль</BlueLink>
                    <BlueLink collapsible={false} to={'/settings'}>Настройки</BlueLink>
                </div>
                <LogoutLink />
            </Menu>
        </div>
    );
};

export default ProfileLink;
