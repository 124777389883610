import React from 'react';
import styles from './FileItem.module.scss';
import classNames from "classnames";
import audioIcon from "../../../../assets/SidebarCards/MaterialsCard/audio.svg"
import imageIcon from "../../../../assets/SidebarCards/MaterialsCard/image.svg"
import documentIcon from "../../../../assets/SidebarCards/MaterialsCard/document.svg"
import videoIcon from "../../../../assets/SidebarCards/MaterialsCard/video.svg"
import hohoroIcon from "../../../../assets/SidebarCards/MaterialsCard/hohoro-document.svg"

const FileItem = ({file, variant = 'materials'}) => {

    let icon = documentIcon

    switch (file.type) {
        case 'image':
            icon = imageIcon
            break
        case 'audio':
            icon = audioIcon
            break
        case 'video':
            icon = videoIcon
            break
    }

    if (variant === 'documents') icon = hohoroIcon

    return (
        <a href={file.link} className={classNames(styles.link, styles[variant])}>
            <div className={styles.icon}>
                <img src={icon} alt=""/>
            </div>
            <div className={styles.info}>
                <div className={classNames(styles.name, variant === 'documents' ? 'body-18px' : 'body-14px')}>
                    {file.name}
                </div>
                <div className={classNames(styles.type)}>
                    {file.format}
                </div>
            </div>
        </a>
    );
};

export default FileItem;
