import {createContext, useContext, useState} from "react";
import AuthService from "../services/AuthService";
import Cookies from "js-cookie";

export const AuthContext = createContext(null)

export const AuthProvider = ({children}) => {

    const [user, setUser] = useState({})
    const [isAuth, setIsAuth] = useState(false)
    const [token, setToken] = useState(Cookies.get('token'))
    const [isLoading, setIsLoading] = useState(false)

    async function login({email, password, remember}) {
        try {
            setIsLoading(true)
            const token = await AuthService.login(email, password, remember)
            setToken(token)
        } catch (e) {
            console.log(e)
        } finally {
            setIsLoading(false)
        }
    }

    async function fetchUser() {
        try {
            setIsLoading(true)
            const user = await AuthService.fetchUser()
            setIsAuth(true)
            setUser(user)
        } catch (e) {
            console.log(e)
        } finally {
            setIsLoading(false)
        }
    }

    async function logOut() {
        try {
            setIsLoading(true)

            await AuthService.logOut()
            setIsAuth(false)
            setUser(null)
            setToken(null)
        } catch (e) {
            console.log(e)
        } finally {
            setIsLoading(false)
        }
    }

    const contextValue = {
        user,
        isLoading,
        isAuth,
        login,
        fetchUser,
        token,
        logOut
    }

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
    const context = useContext(AuthContext)
    if (!context)
        throw new Error('Хук useAuth должен быть использован внутри AuthProvider')

    return context
}
