import React from 'react';
import styles from './EduPageCard.module.scss';
import classNames from "classnames";
import {Link} from "react-router-dom";

const EduPageCard = ({index, module, modules}) => {
    return (
        <div className={styles.wrapper}>
            {module.closed && <div className={classNames(styles.closed, 'header-24px')}>Не открыто</div>}
            <div className={classNames(styles.card, { [`${styles.blur}`]: module.closed })}>
                <div className={styles.content}>
                    <div className={styles.index}>Модуль {index + 1}</div>
                    <h3 className={classNames(styles.title, 'header-32px')}><Link to={module.link} state={{modules}}>{module.title}</Link></h3>
                    <div className={classNames(styles.status, 'body-16px')}>
                        {module.completed
                            ?
                            `Выполнено ${module.completed}/${module.articles.length}`
                            :
                            <span className={'body-18px'}>{`${module.articles.length} уроков`}</span>
                        }
                    </div>
                </div>
                <Link to={module.link} className={styles.link} state={{modules}}></Link>
                <img className={styles.icon} src={module.icon} alt=""/>
            </div>
        </div>
    );
};

export default EduPageCard;
