import React from 'react';
import styles from "./Navbar.module.scss"
import {NavLink} from "react-router-dom";
import {privateRoutes, publicRoutes} from "../../../router/Routes";
import {useAuth} from "../../../context/AuthContext";
import classNames from "classnames";

const Navbar = ({toggle, isShow}) => {

    const {token} = useAuth()

    return (
        <>
            <nav className={styles.row}>
                {token && privateRoutes.map(route => {
                    if (route.header) return (
                        <NavLink to={route.path}
                                 className='uppercase body-12px'
                                 key={route.path}
                        >
                            {route.name}
                        </NavLink>
                    )
                })}
                {publicRoutes.map(route => {
                    if (route.header || route.path === '/login' && !token) return (
                        <NavLink to={route.path}
                                 className='uppercase body-12px'
                                 key={route.path}
                        >
                            {route.name}
                        </NavLink>
                    )
                })}
            </nav>
            <div className={classNames(styles.menuButton, {[`${styles.show}`]: isShow})} onClick={toggle}>
                <div className={styles.menuLine}></div>
            </div>
        </>
    );
};

export default Navbar;
