import React from 'react';
import styles from './SettingsPage.module.scss';
import Card from "../../components/UI/Cards/Card/Card";
import {useTheme} from "../../context/ThemeContext";
import Cards from "../../components/UI/Cards/Cards";
import Button from "../../components/UI/common/Button/Button";
import {useAuth} from "../../context/AuthContext";

const SettingsPage = () => {

    const {theme, setTheme} = useTheme()
    const {logOut} = useAuth()

    const changeTheme = (e) => {
        setTheme(e.target.value)
    }

    return (
        <div className={'page'}>
            <Cards>
                <h2 className={'h2'}>Настройки</h2>
                <Card>
                    <label htmlFor="theme">
                        Тема:
                        <select onChange={changeTheme} id="theme" style={{color: 'var(--color-text-gray)'}} value={theme}>
                            <option value="dark">Темная тема</option>
                            <option value="light">Светлая тема</option>
                        </select>
                    </label>

                    <div style={{marginTop: 24}}>
                        <Button onClick={logOut} size={'auto'}>Выход</Button>
                    </div>
                </Card>
            </Cards>
        </div>
    );
};

export default SettingsPage;
