import React from 'react';
import styles from './Button.module.scss'

const Button = ({children, size = 'full', color = 'green', ...props}) => {
    let className = styles.btn

    if (size === 'full') className += ` ${styles.full}`
    if (size === 'auto') className += ` ${styles.auto}`

    if (color === 'green') className += ` ${styles.green}`
    if (color === 'transparent') className += ` ${styles.transparent}`

    return (
        <button className={className} {...props}>
            {children}
        </button>
    );
};

export default Button;
