import React from 'react';
import styles from './Input.module.scss'
import classNames from "classnames";

const Input = ({label, value, id, onChange, className, ...props}) => {
    return (
        <div className={classNames(styles.wrapper, className)}>
            <label className={value && styles.focused} htmlFor={id}>
                {label}
            </label>
            <input value={value} id={id} onChange={onChange} {...props} />
        </div>
    );
};

export default Input;
