import React, {useEffect, useState} from 'react';
import ContentWrapper from "../../App/ContentWrapper/ContentWrapper";
import styles from './Banner.module.scss'
import classNames from "classnames";
import BannerSwitch from "./BannerSwitch/BannerSwitch";

const Banner = () => {

    const [visible, setVisible] = useState(false)

    useEffect(() => {
        if (localStorage.getItem('new_banner') === null || localStorage.getItem('new_banner') === '1') {
            setVisible(true)
            localStorage.setItem('new_banner', '1')
        }
    }, [])

    const closeBanner = () => {
        localStorage.setItem('new_banner', '0')
        setVisible(visible => !visible)
    }

    return (
        visible &&
        <ContentWrapper contentClass={styles.content}>
            <div className={classNames(styles.top, ['header-24px', 'uppercase'])}>
                Обн<BannerSwitch/>вление платформы партнеров
            </div>
            <div className={styles.bottom + ' body-16px'}>
                только лучше для кофеен на рынке №1
            </div>
            <span onClick={closeBanner} className={styles.closeBtn}></span>
        </ContentWrapper>
    );
};

export default Banner;
