import React from 'react';
import styles from './BlueLink.module.scss';
import {Link} from "react-router-dom";
import classNames from "classnames";

const BlueLink = ({collapsible = true, ...props}) => {
    return (
        <Link {...props} className={classNames(styles.link, {[`${styles.collapsible}`]: collapsible} ,['body-16px'])}>
            {props.children}
        </Link>
    );
};

export default BlueLink;
