import React, {useState} from 'react';
import styles from './EduModulePage.module.scss'
import ContentWrapper from "../../../components/App/ContentWrapper/ContentWrapper";
import PageContent from "../../../components/UI/PageContent/PageContent";
import Sidebar from "../../../components/UI/common/Sidebar/Sidebar";
import PageRow from "../../../components/UI/PageRow/PageRow";
import classNames from "classnames";
import bannerDecor from "../../../assets/pages/EduPage/banner-decor.png"
import ModulesCard from "../../../components/UI/SidebarCards/ModulesCard/ModulesCard";
import ProgressCard from "../../../components/UI/SidebarCards/ProgressCard/ProgressCard";
import {Link, useLocation} from "react-router-dom";

const EduModulePage = () => {

    const {state} = useLocation()

    const {modules} = state

    return (
        <div>
            <div className={'page'}>
                <ContentWrapper>
                    <PageRow>
                        <PageContent
                            headerChildren={
                                <div className={styles.banner}>
                                    <h1 className={classNames(styles.title, 'header-40px')}>Как открыть Индивидуального Предпринимателя</h1>
                                    <img src={bannerDecor} alt="" className={styles.decor}/>
                                </div>
                            }
                        >
                            <ul className={styles.list}>
                                {modules[0].articles.map(article =>
                                    <li key={article.id}>
                                        {article.title}
                                    </li>
                                )}
                            </ul>
                        </PageContent>
                        <Sidebar>
                            <ProgressCard progress={10}/>
                            <ModulesCard modules={modules} />
                        </Sidebar>
                    </PageRow>
                </ContentWrapper>
            </div>
        </div>
    );
};

export default EduModulePage;
