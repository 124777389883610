import React, {useState} from 'react';
import styles from './DocumentsCard.module.scss';
import CardHeader from "../../../../components/UI/Cards/Card/CardHeader/CardHeader";
import Card from "../../../../components/UI/Cards/Card/Card";
import Stack from "../../../../components/UI/common/Stack/Stack";
import FileItem from "../../../../components/UI/common/FileItem/FileItem";

const DocumentsCard = () => {

    const [files, setFiles] = useState([
        {
            id: 0,
            name: 'Партнерский договор №111 от 11.12.2022',
            type: 'document',
            format: 'pdf',
            link: '#'
        },
        {
            id: 1,
            name: 'Заявка заказа №111 от 11.12.2022',
            type: 'document',
            format: 'pdf',
            link: '#'
        },
        {
            id: 2,
            name: 'Партнерский договор №111 от 11.12.2022',
            type: 'document',
            format: 'pdf',
            link: '#'
        },
        {
            id: 3,
            name: 'Заявка заказа №111 от 11.12.2022',
            type: 'document',
            format: 'pdf',
            link: '#'
        },
    ]);

    return (
        <Card height={'fit'}>
            <CardHeader title='Документы' />
            <Stack className={styles.stack}>
                {files.map(file => <FileItem key={file.id} variant={'documents'} file={file} />)}
            </Stack>
        </Card>
    );
}

export default DocumentsCard
