import React from 'react';
import styles from './ServiceCard.module.scss';
import classNames from "classnames";

const ServiceCard = ({card, className, size = 'small'}) => {
    return (
        <a href='#' className={classNames(styles.card, [styles.hit, styles[size], className])}>
            <div className={styles.image}>
                <img src={card.image} alt=""/>
            </div>
            <h6 className={classNames(styles.title, size === 'small' ? 'body-16px' : 'body-18px')}>
                {card.title}
            </h6>
        </a>
    );
};

export default ServiceCard;
