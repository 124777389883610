import React from 'react';

const ShopPage = () => {
    return (
        <div>
            Hohoro Shop
        </div>
    );
};

export default ShopPage;
