import React from 'react';
import styles from './EduBase.module.scss';
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader/CardHeader";
import icon from "../../../../assets/Cards/EduBase/fire.png"
import EduBaseItem from "./EduBaseItem/EduBaseItem";
import {Swiper, SwiperSlide} from "swiper/react";
import { Navigation, Pagination, FreeMode } from "swiper";
import 'swiper/css';
import "swiper/css/navigation";
import "./EduBase-swiper.scss"

const EduBase = ({size, posts}) => {

    return (
        <Card size={size} className={styles.card}>
            <CardHeader
                title='База знаний'
                icon={icon}
                to='edubase'
                linkText='Все статьи'
            />
            {posts &&
                <Swiper
                    modules={[Navigation, Pagination, FreeMode]}
                    className={'edubase-slider'}
                    slidesPerView={'auto'}
                    pagination={{
                        clickable: true
                    }}
                    navigation={true}
                    freeMode={true}
                    spaceBetween={15}
                    breakpoints={{
                        1025: {
                            spaceBetween: 19,
                        }
                    }}
                >
                    {
                        posts.map(post =>
                            <SwiperSlide style={{width: 'auto'}} key={post.id}>
                                <EduBaseItem item={post}/>
                            </SwiperSlide>
                        )
                    }
                </Swiper>
            }
        </Card>
    );
};

export default EduBase;
