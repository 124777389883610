import React, {useEffect} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom'
import {privateRoutes, publicRoutes} from "../../router/Routes";
import {useAuth} from "../../context/AuthContext";
import ProtectedRoute from "../../hoc/ProtectedRoute";

const AppRouter = () => {
    const {token, fetchUser} = useAuth()

    const navigate = useNavigate()

    useEffect(() => {
        if (token) {
            fetchUser()
        }
        else {
            navigate('/login')
        }
    }, [])

    return (
        <Routes>
            <Route element={ <ProtectedRoute condition={token} redirectPath={'/login'}/> }>
                {privateRoutes.map(route =>
                    <Route key={route.path}
                           path={route.path}
                           element={route.element}
                    />
                )}
            </Route>
            {publicRoutes.map(route =>
                <Route key={route.path}
                       path={route.path}
                       element={route.element}
                />
            )}
        </Routes>
    );
};

export default AppRouter;
