import React from 'react';
import styles from './Logo.module.scss'
import {NavLink} from "react-router-dom";

const Logo = ({onlyLogo = false, isLink = true}) => {
    return (
        <div className={styles.row}>
            {isLink
                ?
                <NavLink to='/' className={styles.image}>
                    <svg width="239" height="34" viewBox="0 0 239 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.0247 12.6403H8.36562V0.0919189H0V33.4823H8.36562V20.9339H25.0247V33.4823H33.3904V0.0919189H25.0247V12.6403Z" fill="#414141"/>
                        <path d="M66.7093 0.0918884H50.0502L41.6846 8.45751V25.1166L50.0502 33.4823H66.7093L75.0749 25.1166V8.45751L66.7093 0.0918884ZM66.7093 25.1166H50.0502V8.45751H66.7093V25.1166Z" fill="#414141"/>
                        <path d="M106.591 12.6403H89.932V0.0918884H81.5664V33.4823H89.932V20.9338H106.591V33.4823H114.957V0.0918884H106.591V12.6403Z" fill="#414141"/>
                        <path d="M131.612 0.0918884L123.247 8.45751V25.1166L131.612 33.4823H148.271L156.637 25.1166V8.45751L148.271 0.0918884H131.612ZM148.271 25.1166H131.612V8.45751H148.271V25.1166Z" fill="#414141"/>
                        <path d="M213.975 0.0918884L205.609 8.45751V25.1166L213.975 33.4823H230.634L239 25.1166V8.45751L230.634 0.0918884H213.975ZM230.634 25.1166H213.975V8.45751H230.634V25.1166Z" fill="#414141"/>
                        <path d="M172.286 0.0918884H163.92V33.4823H172.286V25.1166H188.945V33.4823H197.311V25.1166L193.128 20.9338L197.311 16.751V8.45751L188.945 0.0918884H172.286ZM172.286 8.45751H188.945V16.8231H172.286V8.45751Z" fill="#414141"/>
                    </svg>
                </NavLink>
                :
                <div className={styles.image}>
                    <svg width="239" height="34" viewBox="0 0 239 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.0247 12.6403H8.36562V0.0919189H0V33.4823H8.36562V20.9339H25.0247V33.4823H33.3904V0.0919189H25.0247V12.6403Z" fill="#414141"/>
                        <path d="M66.7093 0.0918884H50.0502L41.6846 8.45751V25.1166L50.0502 33.4823H66.7093L75.0749 25.1166V8.45751L66.7093 0.0918884ZM66.7093 25.1166H50.0502V8.45751H66.7093V25.1166Z" fill="#414141"/>
                        <path d="M106.591 12.6403H89.932V0.0918884H81.5664V33.4823H89.932V20.9338H106.591V33.4823H114.957V0.0918884H106.591V12.6403Z" fill="#414141"/>
                        <path d="M131.612 0.0918884L123.247 8.45751V25.1166L131.612 33.4823H148.271L156.637 25.1166V8.45751L148.271 0.0918884H131.612ZM148.271 25.1166H131.612V8.45751H148.271V25.1166Z" fill="#414141"/>
                        <path d="M213.975 0.0918884L205.609 8.45751V25.1166L213.975 33.4823H230.634L239 25.1166V8.45751L230.634 0.0918884H213.975ZM230.634 25.1166H213.975V8.45751H230.634V25.1166Z" fill="#414141"/>
                        <path d="M172.286 0.0918884H163.92V33.4823H172.286V25.1166H188.945V33.4823H197.311V25.1166L193.128 20.9338L197.311 16.751V8.45751L188.945 0.0918884H172.286ZM172.286 8.45751H188.945V16.8231H172.286V8.45751Z" fill="#414141"/>
                    </svg>
                </div>
            }

            { !onlyLogo &&
                <div className={styles.text}>
                    Первый российский бренд технологичных кофеен
                </div>
            }
        </div>
    );
};

export default Logo;
