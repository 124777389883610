import React from 'react';
import styles from './ModulesCard.module.scss';
import SidebarCard from "../SidebarCard/SidebarCard";
import classNames from "classnames";

const ModulesCard = ({modules}) => {

    return (
        <SidebarCard title={'Материалы'}>
            <ul className={styles.list}>
                {modules.map(module =>
                    <li key={module.id} className={classNames(styles.item, {[`${styles.active}`]: module.active}  ,'body-16px')}>
                        {module.title}
                    </li>
                )}
            </ul>
        </SidebarCard>
    );
};

export default ModulesCard;
