import React from 'react';
import styles from './RewardsCard.module.scss';
import CardHeader from "../../../../components/UI/Cards/Card/CardHeader/CardHeader";
import Card from "../../../../components/UI/Cards/Card/Card";

const RewardsCard = ({rewards}) => {
    return (
        <Card height={'fit'}>
            <CardHeader title='Награды' />
            <div className={styles.rewards}>
                {rewards.map(reward =>
                    <div key={reward.id} className={styles.reward}>
                        <div className={styles.icon}>{reward.Icon}</div>
                        <div className={styles.name}>{reward.name}</div>
                    </div>
                )}
            </div>
        </Card>
    );
}

export default RewardsCard
