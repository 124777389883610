import React, {useState} from 'react';
import styles from './PurchasesCard.module.scss';
import CardHeader from "../../../../components/UI/Cards/Card/CardHeader/CardHeader";
import Card from "../../../../components/UI/Cards/Card/Card";
import Button from "../../../../components/UI/common/Button/Button";
import image1 from "../../../../assets/Cards/ServicesShop/image1.png";
import image2 from "../../../../assets/Cards/ServicesShop/image2.png";
import ServiceCard from "../../../../components/UI/Cards/ServicesShop/ServiceCard/ServiceCard";

const PurchasesCard = () => {

    const purchases = [
        {
            id: 1,
            image: image1,
            title: "Помощь с открытием ИП/ООО"
        },
        {
            id: 2,
            image: image2,
            title: "Аутсорсинг обслуживания точек"
        },
        {
            id: 3,
            image: image1,
            title: "Помощь с открытием ИП/ООО"
        },
    ]

    return (
        <Card height={'fit'}>
            <CardHeader title='Последние покупки' />
            <div className={styles.cards}>
                {purchases.map(purchase => <ServiceCard key={purchase.id} card={purchase}/>)}
            </div>
            <div className={styles.action}>
                <Button color={'transparent'}>Все покупки</Button>
            </div>
        </Card>
    );
}

export default PurchasesCard
