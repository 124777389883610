import React from 'react';
import Wrapper from "./Wrapper";
import Content from "./Content";

const ContentWrapper = ({ children, contentClass, wrapperClass }) => {
    return (
        <Wrapper wrapperClass={wrapperClass}>
            <Content contentClass={contentClass}>
                { children }
            </Content>
        </Wrapper>
    );
};
export default ContentWrapper;
