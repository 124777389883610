import React from 'react';
import styles from './ProgressCard.module.scss';
import classNames from "classnames";
import decor from '../../../../assets/Cards/ProgressCard/achievment.svg'
import ProgressBar from "../../common/ProgressBar/ProgressBar";
import SidebarCard from "../SidebarCard/SidebarCard";

const ProgressCard = ({progress = 0}) => {

    return (
        <SidebarCard title={'Прогресс'}>
            <img className={styles.decor} src={decor} alt=""/>
            <div className={styles.progress}>
                <ProgressBar
                    progress={progress}
                />
            </div>
            <div className={classNames(styles.text, 'body-16px')}>
                Выполните все уроки и получите награду
            </div>
        </SidebarCard>
    );
};

export default ProgressCard;
