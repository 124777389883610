import React from 'react';

const Content = ({ children, contentClass }) => {
    const className = contentClass ? contentClass + ' content' : 'content'

    return (
        <div className={className}>
            { children }
        </div>
    );
};

export default Content;
