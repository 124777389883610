import React from 'react';
import styles from './Social.module.scss'

const Social = () => {
    return (
        <div className={styles.row}>
            <a href="#" className={styles.element}>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="15" cy="15" r="14.5" stroke="#E6E8EB"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.15428 14.2544C11.6263 12.7423 13.9409 11.7445 15.0983 11.2634C18.4059 9.88702 19.0918 9.64889 19.5395 9.64175C19.6371 9.63937 19.8586 9.66556 20.0014 9.77986C20.1229 9.8775 20.1562 10.0085 20.1705 10.1013C20.1848 10.1942 20.2062 10.4062 20.1896 10.5705C20.011 12.4541 19.2346 17.0215 18.8417 19.1314C18.675 20.0244 18.3464 20.3221 18.0273 20.353C17.3367 20.4173 16.8104 19.8958 16.1413 19.4576C15.0935 18.7718 14.5005 18.3432 13.4837 17.6716C12.3073 16.8977 13.0694 16.4714 13.7409 15.7761C13.9171 15.5951 16.9628 12.8232 17.0224 12.5708C17.0295 12.5398 17.0367 12.4232 16.9676 12.3612C16.8986 12.2993 16.7962 12.3208 16.72 12.3374C16.6152 12.3612 14.9411 13.4686 11.6977 15.657C11.2214 15.9833 10.7928 16.1428 10.407 16.1333C9.98076 16.1238 9.16397 15.8928 8.55672 15.6951C7.81137 15.4522 7.21841 15.3236 7.2708 14.914C7.297 14.6973 7.59228 14.4782 8.15428 14.2544Z" fill="#8995A6"/>
                </svg>
            </a>
            <a href="#" className={styles.element}>
                <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="15" cy="15.0684" r="14.5" stroke="#E6E8EB"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M13.0683 16.7738V12.5049C14.4081 13.2193 15.7394 13.9279 17.0878 14.6452C15.7451 15.3566 14.4138 16.0623 13.0683 16.7738ZM20.1804 11.715C20.0268 11.0268 19.4778 10.5215 18.8121 10.446C17.2448 10.2688 16.2828 10.2659 14.7011 10.2688C13.1195 10.2659 12.1604 10.2688 10.5902 10.446C9.92735 10.5244 9.37549 11.0297 9.22188 11.715C9 12.6937 9 13.7623 9 14.7671C9 15.7748 9 16.8435 9.21904 17.8192C9.37265 18.5075 9.92451 19.0128 10.5873 19.0883C12.1575 19.2683 13.1167 19.2683 14.6983 19.2683C16.2799 19.2683 17.2419 19.2683 18.8093 19.0883C19.4749 19.0128 20.0211 18.5075 20.1776 17.8192C20.3995 16.8435 20.3995 15.7748 20.3995 14.7671C20.4023 13.7623 20.4023 12.6937 20.1804 11.715Z" fill="#8995A6"/>
                </svg>
            </a>
            <a href="#" className={styles.element}>
                <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="15" cy="15.0684" r="14.5" stroke="#E6E8EB"/>
                    <path d="M19.0884 15.8113C18.855 15.4944 18.9425 15.3677 19.0884 15.1142C19.0884 15.1142 20.8969 12.3893 21.101 11.4704C21.1885 11.1219 21.0719 10.8684 20.6343 10.8684H19.1467C18.7675 10.8684 18.5925 11.0902 18.505 11.312C18.505 11.312 17.7466 13.2765 16.6673 14.5439C16.3173 14.9241 16.1714 15.0192 15.9672 15.0192C15.8797 15.0192 15.7339 14.8924 15.7339 14.5756V11.4387C15.7339 11.0268 15.6172 10.8684 15.3255 10.8684H12.992C12.7586 10.8684 12.6128 11.0585 12.6128 11.2169C12.6128 11.5972 13.1378 11.6922 13.1961 12.7695V15.1142C13.1961 15.6212 13.1086 15.7162 12.9336 15.7162C12.4377 15.7162 11.2126 13.7518 10.4834 11.4704C10.3084 11.0585 10.1917 10.8684 9.78332 10.8684H8.29568C7.88731 10.8684 7.7998 11.0902 7.7998 11.312C7.7998 11.7239 8.29568 13.8151 10.1625 16.5717C11.3876 18.4411 13.1086 19.4868 14.6838 19.4868C15.6172 19.4868 15.7339 19.265 15.7339 18.8847C15.7339 17.0787 15.6464 16.9203 16.1131 16.9203C16.3464 16.9203 16.7256 17.047 17.6007 17.9342C18.6216 19.0115 18.7675 19.4868 19.3509 19.4868H20.8385C21.2761 19.4868 21.4802 19.265 21.3636 18.8214C21.0719 17.8708 19.1759 15.938 19.0884 15.8113Z" fill="#8995A6"/>
                </svg>
            </a>
        </div>
    );
};

export default Social;
