import EduBasePage from "../pages/EduBasePage/EduBasePage";
import DashboardPage from "../pages/DashboardPage/DashboardPage";
import EduPage from "../pages/EduPage/EduPage";
import NewsPage from "../pages/NewsPage/NewsPage";
import ShopPage from "../pages/ShopPage/ShopPage";
import ServicePage from "../pages/ServicesPage/ServicesPage";
import LoginPage from "../pages/LoginPage/LoginPage";
import {DashboardProvider} from "../context/DashboardContext";
import SettingsPage from "../pages/SettingsPage/SettingsPage";
import EduModulePage from "../pages/EduPage/EduModulePage/EduModulePage";
import ProfilePage from "../pages/ProfilePage/ProfilePage";

export const privateRoutes = [
    { path: "/", element: <DashboardProvider><DashboardPage/></DashboardProvider>, name: 'Главная страница', header: false },
    { path: "/edubase", element: <DashboardProvider><EduBasePage/></DashboardProvider>, name: 'База знаний', header: true },
    { path: "/edubase/module", element: <EduModulePage/>, name: 'Модуль обучения', header: false },
    { path: "/edu", element: <EduPage/>, name: 'Обучение', header: true },
    { path: "/news", element: <DashboardProvider><NewsPage/></DashboardProvider>, name: 'Новости', header: true },
    { path: "/shop", element: <ShopPage/>, name: 'Hohoro shop', header: true },
    { path: "/services", element: <ServicePage/>, name: 'Магазин услуг', header: true },
    { path: "/settings", element: <SettingsPage/>, name: 'Настройки', header: false },
    { path: "/profile", element: <ProfilePage/>, name: 'Мой профиль', header: false },
]

export const publicRoutes = [
    { path: "/login", element: <LoginPage/>, name: 'Войти', header: false },
]
