import React from 'react';
import styles from './Menu.module.scss';
import classNames from "classnames";

const Menu = ({isVisible, ...props}) => {

    return (
        <div
            className={classNames(styles.block, {
                [`${styles.visible}`]: isVisible
            })}
        >
            {props.children}
        </div>
    );
};

export default Menu;
