import React, {useState} from 'react';
import styles from './ServicesShop.module.scss';
import image1 from '../../../../assets/Cards/ServicesShop/image1.png'
import image2 from '../../../../assets/Cards/ServicesShop/image2.png'
import Card from "../Card/Card";
import {Link} from "react-router-dom";
import Button from "../../common/Button/Button";
import icon from "../../../../assets/Cards/ServicesShop/cart.png";
import CardHeader from "../Card/CardHeader/CardHeader";
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Pagination} from "swiper";
import "./ServicesShop-swiper.scss"
import ServiceCard from "./ServiceCard/ServiceCard";

const ServicesShop = ({size}) => {

    const [cards, setCards] = useState([
        {
            id: 1,
            image: image1,
            title: "Помощь с открытием ИП/ООО"
        },
        {
            id: 2,
            image: image2,
            title: "Аутсорсинг обслуживания точек"
        },
        {
            id: 3,
            image: image1,
            title: "Помощь с открытием ИП/ООО"
        },
        {
            id: 4,
            image: image2,
            title: "Аутсорсинг обслуживания точек"
        },
        {
            id: 5,
            image: image1,
            title: "Помощь с открытием ИП/ООО"
        },
        {
            id: 6,
            image: image2,
            title: "Аутсорсинг обслуживания точек"
        },
    ])

    return (
        <Card className={styles.card} size={size}>
            <CardHeader
                title='Магазин услуг'
                icon={icon}
                to={'/services'}
                linkText={' '}
            />
            {
                cards && window.matchMedia('(min-width: 1025px)').matches ?
                    <div className={styles.list}>
                        {
                            cards.map(card => (
                                <ServiceCard key={card.id} card={card} />
                            ))
                        }
                    </div>
                    :
                    <Swiper
                        className={`${styles.slider} ServicesShop-slider`}
                        modules={[Pagination, FreeMode]}
                        slidesPerView={'auto'}
                        pagination={{
                            clickable: true
                        }}
                        freeMode={true}
                        spaceBetween={15}
                    >
                        {
                            cards.map(card => (
                                <SwiperSlide
                                    key={card.id}
                                    style={{width: 'auto'}}
                                >
                                    <ServiceCard card={card} className={styles.element}/>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
            }
            <Link className={styles.action} to={'services'}>
                <Button size='full' color='transparent'>Все услуги</Button>
            </Link>
        </Card>
    );
};

export default ServicesShop;
